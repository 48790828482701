<template>
  <div class="header-container">
    <!-- Upper Section -->
    <div class="upper-section">
      <!-- Hamburger Menu (visible on mobile) -->
      <div class="hamburger-menu" @click="toggleMobileMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <!-- Logo -->
      <img src="@/assets/logo-2.png" alt="Logo" class="navbar-logo" />

      <!-- Search Input and Location Dropdown -->
      <div class="search-location-container" ref="searchContainer">
        <input
          type="text"
          class="search-input"
          :class="{ 'input-active': suggestions.length > 0 }"
          placeholder="Search a Local Business..."
          v-model="searchQuery"
          @input="fetchSuggestions"
        />

        <!-- Location Dropdown inside the search input -->
        <div class="location-dropdown" ref="dropdown">
          <div class="location-select" @click="toggleDropdown">
            <span class="location-text">{{ selectedLocation }}</span>
            <svg class="chevron-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
          <ul v-if="isDropdownOpen" class="dropdown-menu">
            <li v-for="location in locations" :key="location" @click="selectLocation(location)">
              {{ location }}
            </li>
          </ul>
        </div>

        <!-- Suggestions Dropdown -->
        <div v-if="suggestions.length" class="suggestions-dropdown">
          <ul>
            <li v-for="business in suggestions" :key="business._id" @click="selectBusiness(business)" class="suggestion-item">
              {{ business.name }}
            </li>
          </ul>
        </div>
      </div>

      <!-- User Authentication / Icons -->
      <div class="icon-container">
        <router-link 
          v-if="authState.user" 
          :to="`/account/${authState.user.id}/orders`" 
          class="user-info-button"
        >
          <img src="@/assets/user.svg" alt="Account" />
          <span>Hi {{ authState.user.fullName }}<br/> Account</span>
        </router-link>
        <div v-else class="icon sign-in" @click="navigateToLogin">
          <img src="@/assets/user.svg" alt="Sign In" />
          <span>Sign In<br />Account</span>
        </div>

        <!-- Notification Icon -->
        <div class="cart-container">
          <div class="cart-icon" @mouseover="showDropdown('notifications')" @mouseleave="hideDropdown('notifications')">
            <img src="@/assets/notification.svg" alt="Notifications"/>
            <span class="item-count">{{ notifications.length }}</span>
            <div class="notification-dropdown" v-if="dropdownVisible.notifications">
              <div v-if="notifications.length > 0">
                <div class="notification-item" v-for="(notification, index) in notifications.slice(0, 10)" :key="index">
                  <div class="notification-header">
                    <span class="notification-title">{{ notification.title }}</span>
                    <button class="remove-button" @click="removeNotification(notification.id)">&times;</button>
                  </div>
                  <div class="notification-body">{{ stripHtmlTags(notification.messageBody) }}</div>
                </div>
                <button class="view-all-notifications-button" @click="viewNotifications">View All Notifications</button>
              </div>
              <div v-else class="empty-notifications">You have no notifications.</div>
            </div>
          </div>
          <div class="cart-icon" @mouseover="showDropdown('shoppingBag')" @mouseleave="hideDropdown('shoppingBag')">
            <img src="@/assets/bag.svg" alt="Shopping Bag"/>
            <span class="item-count">{{ shoppingBagItems.length }}</span>
            <div class="coupon-bag-dropdown" v-if="dropdownVisible.shoppingBag">
              <div v-if="shoppingBagItems.length > 0">
                <div class="coupon-item" v-for="(item, index) in shoppingBagItems.slice(0, 10)" :key="index">
                  <div class="coupon-header">
                    <span class="coupon-title">{{ item.name }}</span>
                    <button class="remove-button" @click="removeFromShoppingBag(item.couponId, item.name)">&times;</button>
                  </div>
                  <div class="coupon-code">
                    <span>{{ item.couponCode }}</span>
                    <button class="copy-button" @click.stop="copyCode(item.couponCode)">Copy</button>
                  </div>
                </div>
                <button class="view-shopping-bag-button" @click="viewShoppingBag">View Coupon Bag</button>
              </div>
              <div v-else class="empty-cart">Your coupon bag is empty.</div>
            </div>
          </div>
          <div class="cart-icon" @mouseover="showDropdown('checkoutCart')" @mouseleave="hideDropdown('checkoutCart')">
            <img src="@/assets/shopping-cart.svg" alt="Cart"/>
            <span class="item-count">{{ checkoutCartItems.length }}</span>
            <div class="cart-dropdown" v-if="dropdownVisible.checkoutCart">
              <div v-if="checkoutCartItems.length > 0">
                <div class="cart-item" v-for="(item, index) in checkoutCartItems.slice(0, 10)" :key="index">
                  <button class="remove-button" @click="removeFromCheckoutCart(item.giftCardId)">×</button>
                  <div class="cart-item-details" @click="navigateToCard(item.giftCardId)">
                    <span class="cart-item-name">{{ item.dealTitle }}</span>
                    <div class="cart-item-price-details">
                      <span class="original-price">${{ item.originalPrice }}</span>
                      <span class="discounted-price">${{ calculateDiscountedPrice(item.originalPrice, item.discount) }}</span>
                      <span class="discount">{{ item.discount }}% OFF</span>
                    </div>
                  </div>
                </div>
                <div class="cart-total">
                  <span>Total:</span>
                  <span>${{ totalCost }}</span>
                </div>
                <button class="view-cart-button" @click="viewCart">View Cart</button>
              </div>
              <div v-else class="empty-cart">Your cart is empty.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Menu (hidden by default) -->
    <div class="mobile-menu" :class="{ 'active': isMobileMenuOpen }">
      <button class="close-menu-button" @click="closeMobileMenu">X</button>
      <router-link to="/" class="nav-link" @click="closeMobileMenu">
        <img src="@/assets/home.svg" alt="Home Icon" /> Home
      </router-link>
      <router-link to="/gift-cards" class="nav-link" @click="closeMobileMenu">
        <img src="@/assets/gift-card-2.svg" alt="Discount Icon" /> Browse Discounted Gift Cards
      </router-link>
      <router-link to="/coupons" class="nav-link" @click="closeMobileMenu">
        <img src="@/assets/tag.svg" alt="Coupon Icon" /> Browse Coupon Savings
      </router-link>
      <router-link v-if="authState.user && (authState.user.isAdmin || authState.user.isBusiness)" to="/dashboard" class="nav-link" @click="closeMobileMenu">
        <img src="@/assets/dashboard.svg" alt="Dashboard Icon" />Dashboard
      </router-link>
    </div>

    <!-- Lower Navigation Links (visible on desktop) -->
    <div class="lower-section desktop-only">
      <router-link to="/" class="nav-link">
        <img src="@/assets/home.svg" alt="Home Icon" /> Home
      </router-link>
      <router-link to="/gift-cards" class="nav-link">
        <img src="@/assets/gift-card-2.svg" alt="Discount Icon" /> Browse Discounted Gift Cards
      </router-link>
      <router-link to="/coupons" class="nav-link">
        <img src="@/assets/tag.svg" alt="Coupon Icon" /> Browse Coupon Savings
      </router-link>
      <router-link v-if="authState.user && (authState.user.isAdmin || authState.user.isBusiness)" to="/dashboard" class="nav-link">
        <img src="@/assets/dashboard.svg" alt="Dashboard Icon" />Dashboard
      </router-link>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { reactive, onMounted, onBeforeUnmount, toRefs, computed } from 'vue';
import { useRouter } from 'vue-router';
import { authState, setUser } from '../../services/auth';

export default {
  name: 'NavBar',
  setup() {
    const router = useRouter();

    const state = reactive({
      user: JSON.parse(localStorage.getItem('user')) || null,
      shoppingBagItems: JSON.parse(localStorage.getItem('shoppingBagItems')) || [],
      checkoutCartItems: JSON.parse(localStorage.getItem('checkoutCartItems')) || [],
      notifications: [],
      dropdownVisible: {
        shoppingBag: false,
        checkoutCart: false,
        notifications: false,
      },
      hideTimeouts: {
        shoppingBag: null,
        checkoutCart: null,
        notifications: null,
      },
      searchQuery: '',
      suggestions: [],
      selectedLocation: 'Stark County, Ohio',
      isDropdownOpen: false,
      locations: ['Stark County, Ohio', 'Summit County, Ohio'],
      isMobileMenuOpen: false,
    });

    const logout = () => {
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      state.user = null;
      window.location.href = `/`;
    };
    
    const toggleMobileMenu = () => {
      state.isMobileMenuOpen = !state.isMobileMenuOpen;
    };

    const closeMobileMenu = () => {
      state.isMobileMenuOpen = false;
    };

    const showDropdown = (cartType) => {
      if (state.hideTimeouts[cartType]) {
        clearTimeout(state.hideTimeouts[cartType]);
      }
      hideAllDropdowns();
      state.dropdownVisible[cartType] = true;
    };

    const hideDropdown = (cartType) => {
      state.hideTimeouts[cartType] = setTimeout(() => {
        state.dropdownVisible[cartType] = false;
      }, 300);
    };

    const hideAllDropdowns = () => {
      Object.keys(state.dropdownVisible).forEach(key => {
        state.dropdownVisible[key] = false;
      });
    };

    const updateShoppingBag = (items) => {
      state.shoppingBagItems = items || [];
      localStorage.setItem('shoppingBagItems', JSON.stringify(items));
    };

    const updateCheckoutCart = (items) => {
      state.checkoutCartItems = items || [];
      localStorage.setItem('checkoutCartItems', JSON.stringify(items));
    };

    const copyCode = (code) => {
      if (code) {
        navigator.clipboard.writeText(code).then(() => {
          alert('Coupon code copied!');
        });
      }
    };

    const removeFromShoppingBag = (couponId, name) => {
      if (!state.user || !state.user.id) return;
      const token = localStorage.getItem('token');
      axios.put(
        `${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-shopping-bag/${state.user.id}`,
        { couponId, name },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      )
      .then(() => {
        let shoppingBagItems = state.shoppingBagItems.filter(item => item.couponId !== couponId || item.name !== name);
        updateShoppingBag(shoppingBagItems);

        const event = new CustomEvent('update-shopping-bag', { detail: shoppingBagItems });
        window.dispatchEvent(event);
      })
      .catch((error) => {
        console.error('Error removing from shopping bag:', error);
      });
    };

    const removeFromCheckoutCart = (giftCardId) => {
      if (!state.user || !state.user.id) {
        let checkoutCartItems = state.checkoutCartItems.filter(item => item.giftCardId !== giftCardId);
        updateCheckoutCart(checkoutCartItems);

        const event = new CustomEvent('update-checkout-cart', { detail: checkoutCartItems });
        window.dispatchEvent(event);
        return;
      }

      const token = localStorage.getItem('token');
      axios.put(
        `${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${state.user.id}`,
        { giftCardId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      )
      .then(() => {
        let checkoutCartItems = state.checkoutCartItems.filter(item => item.giftCardId !== giftCardId);
        updateCheckoutCart(checkoutCartItems);

        const event = new CustomEvent('update-checkout-cart', { detail: checkoutCartItems });
        window.dispatchEvent(event);
      })
      .catch((error) => {
        console.error('Error removing from checkout cart:', error);
      });
    };

    const calculateDiscountedPrice = (originalPrice, discount) => {
      return ((originalPrice || 0) * (1 - (discount || 0) / 100)).toFixed(2);
    };

    const totalCost = computed(() => {
      return state.checkoutCartItems.reduce((total, item) => 
        total + parseFloat(calculateDiscountedPrice(item.originalPrice, item.discount)), 0
      ).toFixed(2);
    });

    const navigateToCoupon = (couponId) => {
      if (couponId) router.push(`/coupon/${couponId}`);
    };

    const navigateToCard = (giftCardId) => {
      if (giftCardId) router.push(`/gift-card/${giftCardId}`);
    };

    const viewShoppingBag = () => {
      if (state.user && state.user.id) router.push(`/account/${state.user.id}/shopping-bag`);
    };

    const viewCart = () => {
      router.push('/cart');
    };

    const viewNotifications = () => {
      if (state.user && state.user.id) router.push(`/account/${state.user.id}/notifications`);
    };

    const fetchNotifications = () => {
      if (!state.user || !state.user.id) return;
      const token = localStorage.getItem('token');
      if (token) {
        axios.get(`${process.env.VUE_APP_API_URL}/api/notifications/user-notifications`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            userId: state.user.id,
          },
        })
        .then((response) => {
          state.notifications = response.data.notifications || [];
        })
        .catch((error) => {
          console.error('Error fetching notifications:', error);
        });
      }
    };

    const removeNotification = async (notificationId) => {
      if (!state.user || !state.user.id) return;
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/notifications/remove-notification`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            params: {
              userId: state.user.id,
              notificationId: notificationId,
            },
          });
          state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
          fetchNotifications();
        } catch (error) {
          console.error('Error removing notification:', error);
        }
      }
    };

    const stripHtmlTags = (html) => {
      if (!html) return '';
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    };

    const toggleDropdown = () => {
      state.isDropdownOpen = !state.isDropdownOpen;
    };

    const selectLocation = (location) => {
      state.selectedLocation = location;
      state.isDropdownOpen = false;
    };

    const fetchSuggestions = async () => {
      if (state.searchQuery.trim().length < 2) {
        state.suggestions = [];
        return;
      }
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/search`, { params: { query: state.searchQuery } });
        state.suggestions = response.data.businesses;
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    const selectBusiness = (business) => {
      router.push(`/business/${business._id}`);
      state.suggestions = [];
    };

    const navigateToLogin = () => {
      router.push('/login');
    };

    const formatDealDetails = (dealDetails) => {
      if (!dealDetails) return '';
      if (dealDetails.discounttype === 'percentageAmount') {
        return `${dealDetails.discount}% off if you spend $${dealDetails.spendamount}`;
      } else if (dealDetails.discounttype === 'amountOff') {
        return `$${dealDetails.regularprice} off`;
      } else if (dealDetails.discounttype === 'buyOneGetOnePercentage') {
        return `${dealDetails.discount}% Off`;
      } else if (dealDetails.discounttype === 'buyOneGetOneAmount') {
        return `$${dealDetails.discount} Off`;
      } else if (dealDetails.discounttype === 'buyOneGetOneFree') {
        return 'Buy 1 Get 1 Free';
      }
      return '';
    };

    const handleClickOutside = (event) => {
      const mobileMenu = document.querySelector('.mobile-menu');
      const hamburgerMenu = document.querySelector('.hamburger-menu');

      if (
        state.isMobileMenuOpen &&
        !mobileMenu.contains(event.target) &&
        !hamburgerMenu.contains(event.target)
      ) {
        closeMobileMenu();
      }
    };

    onMounted(() => {
      window.addEventListener('update-shopping-bag', (event) => {
        updateShoppingBag(event.detail);
      });
      window.addEventListener('update-checkout-cart', (event) => {
        updateCheckoutCart(event.detail);
      });

      fetchNotifications();

      document.addEventListener('click', handleClickOutside);

      window.addEventListener('notification-removed', (event) => {
        const notificationId = event.detail;
        state.notifications = state.notifications.filter(notification => notification.id !== notificationId);
      });
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      authState: reactive(authState),
      ...toRefs(state),
      logout,
      showDropdown,
      hideDropdown,
      hideAllDropdowns,
      copyCode,
      removeFromShoppingBag,
      removeFromCheckoutCart,
      calculateDiscountedPrice,
      totalCost,
      navigateToCoupon,
      navigateToCard,
      viewShoppingBag,
      viewCart,
      viewNotifications,
      stripHtmlTags,
      removeNotification,
      toggleDropdown,
      selectLocation,
      fetchSuggestions,
      selectBusiness,
      navigateToLogin,
      formatDealDetails,
      isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
      toggleMobileMenu,
      closeMobileMenu,
    };
  },
};
</script>

<style scoped>
.header-container {
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.upper-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #4CAF50;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.navbar-logo {
  width: 8%;
  height: auto;
  margin-right: 20px; 
  object-fit:contain;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-location-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 5px 15px;
  flex-grow: 1;
  margin-right: 400px;
  max-width: 800px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.search-input {
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  flex-grow: 1;
  outline: none;
  background-color: transparent;
  color: #333333;
  width: 50%;
}

.search-input::placeholder {
  color: #999999;
}

.location-dropdown {
  position: relative;
  display: inline-block;
}

.location-select {
  display: flex;
  align-items: center;
  background-color: white;
  color: #4CAF50;
  padding: 12px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
}

.location-text {
  margin-right: 8px;
  font-weight: bold;
}

.chevron-icon {
  width: 18px;
  height: 18px;
  transition: transform 0.3s ease;
}

.location-select:hover .chevron-icon {
  transform: translateY(2px);
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.arrow-down {
  border-top: 5px solid #000;
}

.arrow-up {
  border-bottom: 5px solid #000;
}

.location-dropdown span {
  margin-right: 5px;
  font-size: 16px;
}

.dropdown-icon {
  width: 12px;
  height: 12px;
  transition: transform 0.3s ease;
}

.search-location-container {
  position: relative; /* Add this to contain the absolute positioned dropdown */
}

.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.suggestions-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.suggestions-dropdown li {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.suggestions-dropdown li:hover {
  background-color: #f5f5f5;
}

.suggestion-item {
  display: flex;
  align-items: center;
}

.suggestion-item img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  object-fit: contain;
}

.icon-container {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center; /* Changed from flex-direction: column */
  margin-left: 20px;
  color: #ffffff;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.icon:hover {
  opacity: 0.8;
}

.icon img {
  height: 30px;
  width: 30px;
  margin-right: 8px; /* Added margin-right instead of margin-bottom */
  object-fit: contain;
}

.icon span {
  text-align: left; /* Changed from center */
  line-height: 1.2;
}

.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ffffff;
  color: #4CAF50;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
  min-width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.lower-section {
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  background-color: #E8F5E9;
  border-bottom: 1px solid #C8E6C9;
}

.nav-link {
  display: flex;
  align-items: center;
  color: #333333;
  text-decoration: none;
  font-weight: bold;
  margin-right: 30px;
  font-size: 18px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #4CAF50;
}

.nav-link img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
  object-fit: contain;
}

.cart-dropdown{
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.cart-item {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item-details {
  flex-grow: 1;
  cursor: pointer;
}

.cart-item-name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.cart-item-deal {
  font-size: 12px;
  color: #666;
}

.cart-item-price-details {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.original-price {
  text-decoration: line-through;
  color: #888;
}

.discounted-price {
  color: #4caf50;
  font-weight: bold;
}

.discount {
  background-color: #d4edda;
  color: #155724;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 0.75rem;
}

.remove-button {
  width: 15px;
  height: 15px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0;
}

.view-all-notifications-button {
  width: 100%;
  padding: 10px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  border-radius: 4px;
}

.copy-button {
  background-color: #4C6B30;
  color: white;
  border: none;
  padding: 2px 5px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.75rem;
  margin-left: 5px;
}

.view-shopping-bag-button,
.view-cart-button,
.view-notifications-button {
  width: 100%;
  padding: 10px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}

.empty-cart {
  text-align: center;
  color: #999;
  padding: 20px 0;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-weight: bold;
  border-top: 1px solid #eee;
  margin-top: 10px;
}

.cart-container {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.cart-icon {
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}

.cart-icon img{
  height: 30px;
  width: 30px;
}

.item-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #1A3819;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.coupon-bag-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.coupon-item {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
}

.coupon-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.coupon-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.coupon-deal {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.coupon-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
}

.coupon-code span {
  font-family: monospace;
  font-size: 14px;
  color: #333;
}

.copy-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
}

.view-shopping-bag-button {
  width: 100%;
  padding: 10px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  border-radius: 4px;
}

.notification-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.notification-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.notification-body {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.welcome-message {
  color: white;
  font-size: 14px;
  margin-bottom: 5px;
}

.account-link {
  display: flex;
  align-items: center;
}

.account-link img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.account-link a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.user-info-button {
  display: flex;
  align-items: center;
  background-color: #45a049;  /* Slightly darker green for contrast */
  border-radius: 20px;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;  /* Remove default link underline */
}

.user-info-button:hover {
  background-color: #3d8b3d;
}

.user-info-button img {
  height: 30px;
  width: 30px;
  margin-right: 8px;
}

.user-info-button span {
  color: white;
  font-size: 14px;
  white-space: nowrap;
}

.close-menu-button {
  background: none;
  border: none;
  color: #333;
  font-size: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1001;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: fixed; /* Keep hamburger menu fixed on the page */
  top: 20px;
  left: 20px;
  z-index: 10000; /* Ensure it stays on top of everything */
}

.hamburger-menu span {
  width: 30px;
  height: 3px;
  background: white;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.mobile-menu {
  display: none;
  flex-direction: column;
  background-color: #E8F5E9;
  position: fixed; /* Ensure the mobile menu is always accessible */
  top: 0;
  left: 0;
  height: 100vh;
  width: 80%;
  max-width: 300px;
  padding: 70px 20px 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 9999; 
  overflow-y: auto; /* Allow scrolling if menu content overflows */
}

.mobile-menu.active {
  transform: translateX(0); /* Slide the mobile menu in when active */
}

.mobile-menu .nav-link {
  margin: 10px 0;
  font-size: 18px;
}

.desktop-only {
  display: flex;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  min-width: 160px;
  padding: 8px 0;
  margin: 8px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.dropdown-menu li {
  padding: 8px 16px;
  color: #333;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f5f5f5;
}

@media (max-width: 1024px) {
  .upper-section {
    flex-wrap: wrap;
    justify-content: space-between;
    position: fixed;
  }

  .navbar-logo {
    order: 2;
    margin: 0 auto;
  }

  .search-location-container {
    order: 3;
    width: 100%;
    margin: 10px 0;
    max-width: none;
  }

  .icon-container {
    order: 4;
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
  }

  .hamburger-menu {
    display: flex; /* Ensure the hamburger menu is visible */
    position: sticky; /* Use sticky positioning */
    top: 0; /* Stick to the top of the viewport */
    left: 0;
    z-index: 1001; /* Ensure it's clickable over other content */
  }

  .desktop-only {
    display: none;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    background-color: #E8F5E9;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80%;
    max-width: 300px;
    padding: 70px 20px 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 9999;
    overflow-y: auto;
  }

  .mobile-menu.active {
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .upper-section {
    padding: 10px;
  }

  .search-location-container {
    flex-direction: column;
    align-items: stretch;
  }

  .location-dropdown {
    border-left: none;
    border-top: 1px solid #E0E0E0;
    margin-left: 0;
    margin-top: 5px;
    padding-top: 5px;
  }

  .icon-container {
    flex-wrap: wrap;
  }

  .icon {
    margin: 5px;
  }

  .cart-dropdown,
  .notification-dropdown,
  .coupon-bag-dropdown {
    width: 280px;
    right: -10px;
  }

  .cart-dropdown {
    transform: translateX(1.5%);
  }

  .notification-dropdown {
    transform: translateX(33%);
  }

  .coupon-bag-dropdown {
    transform: translateX(17%);
  }
}

@media (max-width: 480px) {
  .header-container{
    width: 85%;
  }

  .navbar-logo {
    width: 120px;
  }

  .search-input {
    width: 100%;
  }

  .user-info-button,
  .icon span {
    font-size: 12px;
  }

  .cart-icon img {
    height: 24px;
    width: 24px;
  }

  .item-count {
    font-size: 10px;
    padding: 1px 4px;
  }
}
</style>