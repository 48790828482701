<template>
  <div class="step-preview">
    <MetaManager :title="metaTitle" :description="metaDescription" />

    <!-- Deal Title and Business Info -->
    <div class="deal-header">
      <h2 class="deal-title">{{ couponDetails.dealtitle }}</h2>
      <div class="business-info">
        <h3 class="business-title">{{ couponDetails.businessName }} | <i class="fas fa-star" style="color: #FFD705;"></i> <strong style="color: black;">{{ averageRating }}</strong> <strong style="color: black;">(</strong>{{ reviews.length }} reviews<strong style="color: black;">)</strong></h3>
        <p>Expires on: {{ couponDetails.expirationDate }}</p>
        <div class="button-container" v-if="isLoggedIn">
        <button class="action-button subscribe-button" @click="subscribeToBusiness">
          {{ isFan ? 'Update Preferences' : 'Become a Fan' }}
        </button>
        <button class="action-button shopping-bag" @click="toggleCouponInBag">
          {{ isInShoppingBag ? 'Remove from Coupon Bag' : 'Add to Coupon Bag' }}
        </button>
        <button class="action-button print" @click="printCoupon">Print</button>
       </div>
      </div>
      
    </div>
    

    <div class="content-container">
      <!-- Main Content -->
      <div class="main-content">
        <!-- Image Viewer -->
        <div class="image-viewer">
          <button class="main-arrow left" v-if="images.length > 0" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
          <div class="main-image">
            <img v-if="images.length > 0" :src="images[selectedImageIndex]" alt="Main Image" />
            <img v-else src="@/assets/default-image.png" alt="Default Image" />
          </div>
          <button class="main-arrow right" v-if="images.length > 0" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
        </div>

        <!-- Thumbnails -->
        <div class="thumbnail-slider-container" v-if="images.length > 0">
          <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
            <span>&#9664;</span>
          </button>
          <div class="thumbnail-slider">
            <div class="thumbnail-container">
              <div class="image-preview" v-for="(image, index) in visibleThumbnails" :key="index" @click="selectImage(index + currentBatch * thumbnailsPerPage)">
                <img :src="image" :alt="'Image ' + (index + 1)" />
              </div>
            </div>
          </div>
          <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
            <span>&#9654;</span>
          </button>
        </div>

        <!-- Nav Links for About, Fine Print, Reviews -->
        <div class="section-nav">
          <a href="#about">About</a>
          <a href="#fine-print">Fine Print</a>
          <a href="#reviews">Reviews</a>
        </div>
        <hr class="text-separator" />

        <!-- About Deal -->
        <div id="about" class="about-deal" v-if="couponDetails.dealdetails">
          <h2>About This Coupon</h2>
          <div v-html="couponDetails.dealdetails"></div>
        </div>

        <!-- Fine Print -->
        <div id="fine-print" class="fine-print" v-if="couponDetails.dealfineprint">
          <h2>Fine Print</h2>
          <hr class="text-separator" />
          <div v-html="couponDetails.dealfineprint"></div>
        </div>

        <!-- Customer Reviews -->
        <div id="reviews" class="customer-reviews">
          <CustomerReviews :reviews="reviews" :averageRating="averageRating" :couponId="couponDetails.id" @review-added="addReview" />
        </div>
      </div>
    </div>

    <!-- Explore More Section -->
    <div class="explore-more-section">
      <h2>Explore More</h2>
      <div class="deals-grid-horizontal">
        <CouponCard
          v-for="(deal, index) in exploreMoreDeals"
          :key="index"
          :couponId="deal._id"
          :dealTitle="deal.dealtitle"
          :couponCode="deal.couponcode[0]"
          :couponImage="getCouponImage(deal)"
          :category="deal.category"
          :rating="deal.rating"
        />
      </div>
    </div>

    <!-- Sponsored Deals Section -->
    <div v-if="sponsoredDeals.length > 0" class="sponsored-deals-section">
        <h2>Sponsored Deals</h2>
        <div class="deals-grid-horizontal">
          <CouponCard
            v-for="(deal, index) in sponsoredDeals"
            :key="index"
            :couponId="deal._id"
            :dealTitle="deal.dealtitle"
            :couponCode="deal.couponcode[0]"
            :couponImage="getCouponImage(deal)"
            :category="deal.category"
            :rating="deal.rating"
          />
      </div>
    </div>

    <!-- Notification Modal -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal" @click.stop>
        <h3>Subscribe to Notifications</h3>
        <p>Become a fan and receive notifications when this business posts new coupons, gift cards, or updates</p>
        <form @submit.prevent="submitSubscription">
          <div class="toggle-group">
            <div class="toggle-item">
              <label for="email">Email</label>
              <label class="switch">
                <input type="checkbox" id="email" v-model="subscription.email">
                <span class="slider round"></span>
              </label>
            </div>
            <div class="toggle-item">
              <label for="text">Text</label>
              <label class="switch">
                <input type="checkbox" id="text" v-model="subscription.text">
                <span class="slider round"></span>
              </label>
            </div>
            <div class="toggle-item">
              <label for="notifications">Notifications</label>
              <label class="switch">
                <input type="checkbox" id="notifications" v-model="subscription.notifications">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="modal-buttons">
            <button type="submit" class="submit-button">Submit</button>
            <button v-if="isFan" type="button" class="unsubscribe-button" @click="unsubscribe">Unsubscribe</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CustomerReviews from '@/components/Reviews/CustomerReviews.vue';
import CouponCard from '@/components/Coupon Components/CouponCard.vue'; // Ensure correct import
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'CouponPage',
  components: {
    CustomerReviews,
    CouponCard, // Register the component
    MetaManager,
  },
  data() {
    return {
      couponDetails: {
        dealtitle: '',
        category: '',
        dealdetails: '',
        dealfineprint: '',
        dealNames: [],
        discounts: [],
        maxPerMonth: [],
        couponCodes: [],
        imageLinks: [],
        regularPrices: [],
        spendAmounts: [],
        discountTypes: [],
        expirationDate: '',
      },
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      thumbnailsPerPage: 6,
      selectedOptionId: 0,
      isLoggedIn: false,
      copiedIndex: null,
      copyButtonText: 'Copy',
      sortOption: 'highest-rated',
      reviews: [],
      showModal: false,
      subscription: {
        email: true,
        text: true,
        notifications: true
      },
      isInShoppingBag: false,
      averageRating: 0,
      isFan: false,
      relatedCoupons: [], // Ensure this is populated
      relatedStartIndex: 0,
      relatedEndIndex: 1, // Initially show two items
      displayedAds: [],
      token: localStorage.getItem('token'),
      metaTitle: '',
      metaDescription: '',
      sponsoredDeals: [], // Sponsored deals data
    };
  },
  computed: {
    visibleThumbnails() {
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
    selectedDeal() {
      return {
        name: this.couponDetails.dealNames[this.selectedOptionId],
        couponCode: this.couponDetails.couponCodes[this.selectedOptionId],
        discountType: this.couponDetails.discountTypes[this.selectedOptionId]
      };
    },
    visibleRelatedCoupons() {
      return this.relatedCoupons.slice(this.relatedStartIndex, this.relatedEndIndex + 1);
    },
    shoppingBagButtonText() {
      return this.isInShoppingBag ? 'Remove from Coupon Bag' : 'Add to Coupon Bag';
    }
  },
  watch: {
    selectedOptionId() {
      const shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
      const selectedDealName = this.couponDetails.dealtitle;
      this.isInShoppingBag = shoppingBagItems.some(item => item.couponId === this.couponDetails.id && item.name === selectedDealName);
      this.$nextTick(() => {
        this.checkIfInShoppingBag();
      });
    },
    couponDetails: {
      handler() {
        this.$nextTick(() => {
          this.checkIfInShoppingBag();
        });
      },
      deep: true
    }
  },
  methods: {
    async fetchCouponData() {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const userId = user ? user.id : null;

        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/coupons/${this.$route.params.id}`, {
          params: { userId }
        });
        const data = response.data;

        this.couponDetails = {
          ...data,
          dealNames: data.dealname || [],
          discounts: data.discount || [],
          maxPerMonth: data.maxpermonth || [],
          couponCodes: data.couponcode || [],
          imageLinks: data.image_links || [],
          regularPrices: data.regularprice || [],
          spendAmounts: data.spendamount || [],
          discountTypes: data.discounttype || [],
          businessName: data.business_name || [],
          expirationDate: data.expirationdate || '',
        };

        // Set meta title and description
        this.metaTitle = this.couponDetails.dealtitle;
        this.metaDescription = this.couponDetails.dealdetails 
        ? this.stripHtmlTags(this.couponDetails.dealdetails).substring(0, 160)
        : 'Check out this amazing coupon!';

        this.images = this.couponDetails.imageLinks;
        this.reviews = data.reviews || [];
        this.updateAverageRating();
        this.checkNotificationPreferences();
        this.checkIfInShoppingBag();
      } catch (error) {
        console.error('Error fetching coupon data:', error);
      }
    },
    stripHtmlTags(html) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    },
    async fetchRelatedCoupons() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/related/${this.$route.params.id}`);
        const { exploreMoreDeals, sponsoredDeals } = response.data;

        // Set Explore More and Sponsored Deals
        this.exploreMoreDeals = exploreMoreDeals;
        this.sponsoredDeals = sponsoredDeals;
      } catch (error) {
        console.error('Error fetching related coupons:', error);
        // Set empty arrays if there's an error
        this.exploreMoreDeals = [];
        this.sponsoredDeals = [];
      }
    },
    async fetchSponsoredCoupons() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/related/sponsored-coupons/${this.$route.params.id}`);
        this.sponsoredDeals = response.data.sponsoredDeals;
      } catch (error) {
        console.error('Error fetching sponsored coupons:', error);
      }
    },
    getCouponImage(deal) {
      // Check if image_links exists and is an array with at least one item
      if (Array.isArray(deal.image_links) && deal.image_links.length > 0) {
        return deal.image_links[0]; // Return the first image
      }
      // If image_links is a string, return it directly
      if (typeof deal.image_links === 'string') {
        return deal.image_links;
      }
      // If no valid image, return an empty string
      return '';
    },
    processDiscount(coupon) {
      const { discount, regularprice, spendamount, discounttype } = coupon;
      const isRegularPriceValid = regularprice[0] !== 0;
      const isSpendAmountValid = spendamount[0] !== 0;

      return {
        discount: discount[0],
        regularprice: isRegularPriceValid ? regularprice : null,
        spendamount: isSpendAmountValid ? spendamount : null,
        discounttype: discounttype[0]
      };
    },
    checkNotificationPreferences() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.notification_preferences) {
        this.isFan = false;
        return;
      }

      const notificationPreferences = user.notification_preferences;
      const businessId = this.couponDetails.business_id;

      const userPreference = notificationPreferences[businessId];

      if (userPreference) {
        this.isFan = true;
        this.subscription.email = userPreference.email || false;
        this.subscription.text = userPreference.text || false;
        this.subscription.notifications = userPreference.notifications || false;
      } else {
        this.isFan = false;
      }
    },
    async unsubscribe() {
      this.showModal = false;

      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user || !user.id) {
        this.showMessage('Please log in to update your subscription preferences.');
        return;
      }

      const userId = user.id;
      const couponId = this.$route.params.id;

      try {
        await axios.put(`${process.env.VUE_APP_API_URL}/api/update-notifications/remove-preferences`, {
          couponId,
          userId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });


        // Update localStorage to remove the business preferences
        const businessId = this.couponDetails.business_id;

        if (user.notification_preferences && user.notification_preferences[businessId]) {
          delete user.notification_preferences[businessId];
        }

        localStorage.setItem('user', JSON.stringify(user));
        this.isFan = false;

      } catch (error) {
        console.error('Error unsubscribing:', error);
      }
    },
    checkIfInShoppingBag() {
      if (!this.couponDetails || !this.$route.params.id) {
        return;
      }
      const shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
      const selectedDealName = this.couponDetails.dealtitle;
      this.isInShoppingBag = shoppingBagItems.some(item => 
        item.couponId === this.$route.params.id && item.name === selectedDealName
      );
    },
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    printCoupon() {
      if (this.images.length > 0) {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Coupon</title>
              <style>
                body {
                  margin: 0;
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                }
                img {
                  max-width: 100%;
                  max-height: 100vh;
                  object-fit: contain;
                }
              </style>
            </head>
            <body>
              <img src="${this.images[0]}" alt="Coupon">
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.onload = function() {
          printWindow.focus();
          printWindow.print();
          printWindow.onafterprint = function() {
            printWindow.close();
          };
        };
      } else {
        console.error('No images available for printing');
        // You might want to show an error message to the user here
      }
    },
    addToEWallet() {
      // Add to e-wallet logic here
    },
    handleDealClick(event) {
      event.stopPropagation();
      if (!this.isLoggedIn) {
        localStorage.setItem('redirectAfterLogin', this.$route.fullPath);
        this.$router.push('/login');
        window.scrollTo(0, 0);
      }
    },
    copyCode(code, index) {
      navigator.clipboard.writeText(code).then(() => {
        this.copiedIndex = index;
        setTimeout(() => {
          this.copiedIndex = null;
        }, 2000);
      });
    },
    subscribeToBusiness() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async submitSubscription() {
      this.showModal = false;

      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user || !user.id) {
        this.showMessage('Please log in to update your subscription preferences.');
        return;
      }

      const userId = user.id;
      const couponId = this.$route.params.id;

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/update-notifications`, {
          couponId,
          userId,
          notifications: {
            email: this.subscription.email,
            notifications: this.subscription.notifications,
            text: this.subscription.text
          },
          isFan: true
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });



        // Update localStorage with new preferences
        const businessId = this.couponDetails.business_id;

        // Ensure notification_preferences exists in the user object
        if (!user.notification_preferences) {
          user.notification_preferences = {};
        }

        // Update the notification preferences for the specific business
        user.notification_preferences[businessId] = {
          email: this.subscription.email,
          text: this.subscription.text,
          notifications: this.subscription.notifications,
          isFan: true
        };

        // Update the user object in localStorage
        localStorage.setItem('user', JSON.stringify(user));
        this.isFan = true;

      } catch (error) {
        console.error('Error updating subscription preferences:', error);
      }
    },
    async toggleCouponInBag() {
      if (this.isInShoppingBag) {
        await this.removeCoupon();
      } else {
        await this.saveCoupon();
      }
      this.checkIfInShoppingBag();
    },
    async saveCoupon() {
      const userId = JSON.parse(localStorage.getItem('user')).id;
      const shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
      const selectedDealName = this.couponDetails.dealtitle;
      const couponExists = shoppingBagItems.some(item => item.couponId === this.$route.params.id && item.name === selectedDealName);

      if (couponExists) {
        console.log('Coupon already saved');
      } else {
        try {
          const token = localStorage.getItem('token');
          await axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/${userId}`, {
            couponId: this.$route.params.id,
            name: selectedDealName,
            couponCode: this.couponDetails.couponCodes[this.selectedOptionId],
            couponImage: this.couponDetails.imageLinks[this.selectedOptionId],
            category: this.couponDetails.category
          }, {
            headers: { 'Authorization': `Bearer ${token}`}
          });
          shoppingBagItems.push({
            couponId: this.$route.params.id,
            name: selectedDealName,
            couponCode: this.couponDetails.couponCodes[this.selectedOptionId],
            couponImage: this.couponDetails.imageLinks[this.selectedOptionId],
            category: this.couponDetails.category
          });
          localStorage.setItem('shoppingBagItems', JSON.stringify(shoppingBagItems));
          const event = new CustomEvent('update-shopping-bag', { detail: shoppingBagItems });
          window.dispatchEvent(event);
          this.isInShoppingBag = true;
          this.$forceUpdate();
        } catch (error) {
          console.error('Error saving to shopping cart:', error);
        }
      }
    },
    async removeCoupon() {
      const userId = JSON.parse(localStorage.getItem('user')).id;
      const selectedDealName = this.couponDetails.dealtitle;
      try {
        await axios.put(`${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-shopping-bag/${userId}`, {
          couponId: this.$route.params.id,
          name: selectedDealName,
        }, {
          headers: { 'Authorization': `Bearer ${this.token}` }
        });
        let shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
        shoppingBagItems = shoppingBagItems.filter(item => 
          !(item.couponId === this.$route.params.id && item.name === selectedDealName)
        );
        localStorage.setItem('shoppingBagItems', JSON.stringify(shoppingBagItems));
        const event = new CustomEvent('update-shopping-bag', { detail: shoppingBagItems });
        window.dispatchEvent(event);
        this.isInShoppingBag = false;
        this.$forceUpdate();
      } catch (error) {
        console.error('Error removing from shopping bag:', error);
      }
    },
    updateAverageRating() {
      if (this.reviews.length === 0) {
        this.averageRating = 0;
      } else {
        const total = this.reviews.reduce((sum, review) => sum + parseFloat(review.rating), 0);
        this.averageRating = (total / this.reviews.length).toFixed(1);
      }
    },
    addReview(newReview) {
      this.reviews.push(newReview);
      this.updateAverageRating();
    },
    prevRelatedCoupon() {
      if (this.relatedStartIndex > 0) {
        this.relatedStartIndex--;
        this.relatedEndIndex--;
      }
    },
    nextRelatedCoupon() {
      if (this.relatedEndIndex < this.relatedCoupons.length - 1) {
        this.relatedStartIndex++;
        this.relatedEndIndex++;
      }
    },

    shareOnFacebook() {
    try {
      const currentUrl = window.location.href;
      const encodedUrl = encodeURIComponent(currentUrl);
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
      window.open(facebookUrl, '_blank', 'width=600,height=400,scrollbars=no,resizable=no,toolbar=no,menubar=no,location=no,directories=no,status=no');
    } catch (error) {
      console.error('Error sharing on Facebook:', error);
    }
  },

    shareViaEmail() {
      const subject = encodeURIComponent(this.couponDetails.dealtitle);
      const body = encodeURIComponent(`Check out this deal: ${this.couponDetails.dealtitle}\n\n${window.location.href}`);
      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    },

    shareOnPinterest() {
      const url = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}&media=${encodeURIComponent(this.images[0])}&description=${encodeURIComponent(this.couponDetails.dealtitle)}`;
      window.open(url, '_blank');
    },

    shareOnTwitter() {
      const text = encodeURIComponent(`Check out this deal: ${this.couponDetails.dealtitle}`);
      const url = `https://twitter.com/intent/tweet?text=${text}&url=${encodeURIComponent(window.location.href)}`;
      window.open(url, '_blank');
    },
  },
  async mounted() {
    await this.fetchCouponData();
    await this.fetchRelatedCoupons();
    await this.fetchSponsoredCoupons();
    this.isLoggedIn = !!localStorage.getItem('user');
    this.$nextTick(() => {
      this.checkIfInShoppingBag();
    });
    window.addEventListener('update-shopping-bag', this.checkIfInShoppingBag);
    window.scrollTo(0, 0);

    const shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
    const selectedDealName = this.couponDetails.dealNames[this.selectedOptionId];
    this.isInShoppingBag = shoppingBagItems.some(item => item.couponId === this.couponDetails.id && item.name === selectedDealName);

    this.checkNotificationPreferences();
  },
  beforeUnmount() {
    window.removeEventListener('update-shopping-bag', this.checkIfInShoppingBag);
  },
};
</script>

<style scoped>
.step-preview {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.content-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.left-side,
.right-side {
  width: 44%;
  box-sizing: border-box;
}

.left-side {
  margin-left: 5%;
}

.right-side {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  align-self: flex-start;
}

.coupon-header h3 {
  margin: 0;
  margin-bottom: 20px;
}

.deal-title {
  text-align: left;
  width: 100%;
  margin-left: 5%;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.business-info{
  text-align: left;
  width: 100%;
  margin-left: 5%;
  font-size: 0.95em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.image-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-color: white ;
}

.main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
}

.main-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-sizing: border-box;
  background-color: white;
}

.main-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.main-arrow.left {
  left: 10px;
}

.main-arrow.right {
  right: 10px;
}

.thumbnail-slider-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;
  box-sizing: border-box;
}

.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 30px);
  box-sizing: border-box;
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
}

.image-preview {
  width: 109px;
  height: 109px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-sizing: border-box;
}

.thumbnail-arrow {
  width: 10px;
  height: 110px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 0px;
}

.fine-print {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 50px;
  box-sizing: border-box;
}

.about-deal {
  width: 100%;
  margin-top: 50px;
  box-sizing: border-box;
}

.customer-reviews {
  width: 100%;
  margin-top: 50px;
  box-sizing: border-box;
  margin-bottom: 50px;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.review-rating {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.review-rating .rating {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
  box-sizing: border-box;
}

.review-rating .stars {
  font-size: 1.5em;
  color: #ffc107;
  margin-right: 10px;
  box-sizing: border-box;
}

.review-rating .total-reviews {
  font-size: 1em;
  color: #666;
  box-sizing: border-box;
}

.sort-dropdown {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.sort-dropdown label {
  margin-right: 5px;
  font-size: 1em;
  color: #333;
  box-sizing: border-box;
}

.sort-dropdown select {
  font-size: 1em;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.options-list {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.option-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.option-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.option-label {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.option-header {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.option-details {
  margin-bottom: 8px;
  box-sizing: border-box;
}

.option-detail {
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.coupon-code-container {
  margin: 10px 0;
  text-align: left;
  box-sizing: border-box;
}

.coupon-code-flex {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.get-deal-button-container {
  position: relative;
  margin: 10px 0;
  overflow: hidden;
  box-sizing: border-box;
}

.get-deal-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  border: none;
  outline: none;
  transition: transform 0.3s ease;
  z-index: 2;
  box-sizing: border-box;
}

.get-deal-button::before,
.get-deal-button::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.get-deal-button::before {
  border-width: 0 20px 20px 0;
  border-color: #6E9F49 transparent transparent transparent;
}

.get-deal-button::after {
  border-width: 10px 10px 0 0;
  border-color: #4C6B30 transparent transparent transparent;
}

.get-deal-button:hover {
  transform: translateX(-5px);
}

.dashed-box {
  position: absolute;
  top: 50%;
  right: 570px;
  transform: translateY(-50%) translateX(50%);
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  border: 1px dashed #ccc;
  padding: 9px 10px;
  background-color: #fff;
  z-index: 1;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.business-title{
  color: #757575;
}

.coupon-code-box {
  display: inline-block;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  border: 1px dashed #ccc;
  padding: 5px 10px;
  background-color: #fff;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.copy-button {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4C6B30;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.separator {
  width: 60%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  box-sizing: border-box;
}

.text-separator{
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  box-sizing: border-box;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 90%;
}

.action-button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mobile-button-container{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-sizing: border-box;
}

.shopping-bag,
.print,
.e-wallet,
.subscribe-button {
  width: 55%;
  padding: 7px;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.shopping-bag {
  background-color: #4C6B30;
  color: white;
}

.shopping-bag:hover {
  background-color: #3F5930;
  cursor: pointer;
}

.print {
  background-color: #6E9F49;
  color: white;
}

.print:hover {
  background-color: #A3BF8F;
  cursor: pointer;
}

.e-wallet {
  background-color: #ffc107;
  color: white;
}

.e-wallet:hover {
  background-color: #ffcd39;
  cursor: pointer;
}

.subscribe-button {
  background-color: #8DB36D;
  color: white;
}

.subscribe-button:hover {
  background-color: #7FAF57;
  cursor: pointer;
}

.share-section {
  margin-top: 20px;
  text-align: left;
  margin-left: 40px;
  box-sizing: border-box;
}

.share-section h3 {
  margin: 0 0 10px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.social-icons {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
}

.social-icon {
  font-size: 24px;
  color: #4C6B30;
  text-decoration: none;
  box-sizing: border-box;
}

.social-icon:hover {
  color: #6E9F49;
  box-sizing: border-box;
}

.ads-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 21%;
  box-sizing: border-box;
}

.ads-section h3 {
  margin-top: 20px;
  text-align: left;
  margin-left: 3.5%;
  box-sizing: border-box;
}

.ad-item {
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ad-item img {
  max-width: 30%;
  height: auto;
  box-sizing: border-box;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.toggle-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.toggle-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #4C6B30;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkbox-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.checkbox-group div {
  display: flex;
  align-items: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.submit-button,
.unsubscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button {
  background-color: #4caf50;
  color: white;
}

.submit-button:hover {
  background-color: #45a049;
}

.unsubscribe-button {
  background-color: #f44336;
  color: white;
}

.unsubscribe-button:hover {
  background-color: #d32f2f;
}

.related-coupons {
  margin-top: 50px;
}

.related-slider {
  display: flex;
  align-items: center;
}

.related-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.related-arrow.left {
  margin-right: 10px;
}

.related-arrow.right {
  margin-left: 10px;
}

.related-coupon-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  overflow: hidden;
}

.coupon-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.coupon-card img {
  max-width: 100%;
  border-radius: 8px;
}

.coupon-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.coupon-card p {
  font-size: 1em;
  margin-bottom: 10px;
}

.coupon-card .button {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1em;
}

.coupon-card .button:hover {
  background-color: #0056b3;
}

.mobile-options-list {
  display: none; /* Hide the mobile options list by default */
}

.deals-grid-horizontal {
  display: flex;
  justify-content: flex-start;
  gap: 10px; /* Reduced gap */
  width: 100%;
  overflow: visible; /* Allow overflow to be visible */
}

.explore-more-section,
.sponsored-deals-section {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 50px;
}

.explore-more-section h2,
.sponsored-deals-section h2 {
  text-align: left;
  font-size: 1.3em;
  margin-bottom: 20px;
}

.deals-grid-horizontal :deep(.coupon-card) {
  flex: 0 0 calc(25% - 15px);
  max-width: calc(32% - 15px);
}

.section-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;  /* This creates space between the items */
}

.section-nav span {
  display: inline-block;
}

.section-nav a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.section-nav a:hover,
.section-nav a:focus {
  color: #000;
}

/* Ensure no default list styling is interfering */
.section-nav ul,
.section-nav li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.deals-grid-horizontal  :deep(.coupon-card) {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.deals-grid-horizontal  :deep(.coupon-card:hover) {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media(max-width: 1650px){
  .dashed-box {
    right: 445px;
  }
}

@media(max-width: 1600px){
  .dashed-box {
    right: 430px;
  }
}

@media (max-width: 1500px) {
  .left-side,
  .right-side {
    width: 48%;
  }

  .dashed-box {
    right: 400px;
  }
}

@media(max-width: 1400px){
  .dashed-box {
    right: 360px;
  }
}

@media(max-width: 1250px){
  .dashed-box {
    right: 290px;
  }
}

@media (max-width: 1200px) {
  .step-preview {
    padding: 0 20px;
    margin-top: 250px;
  }

  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .left-side,
  .right-side {
    width: 100%;
    margin: 0;
  }

  .right-side {
    display: none;
  }

  .mobile-options-list {
    display: block; /* Show the mobile options list */
    padding: 50px 0;
    text-align: center;
  }

  .option-label {
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .coupon-code-container, .coupon-code-flex {
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .separator {
    width: 100%;
  }

  .deal-title {
    margin-left: 0;
    text-align: center;
  }

  .about-deal {
    text-align: center;
  }

  .share-section {
    text-align: center;
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .social-icons {
    display: flex; /* This makes .social-icons a flex container */
    justify-content: center; /* This centers the flex items (children) horizontally */
    align-items: center; /* This centers the flex items vertically, if needed */
    text-align: center;
  }

  .fine-print {
    text-align: center;
  }

  .ads-section {
    margin-left: 0;
    text-align: center; /* Center align ads section */
  }

  .ad-item img {
    max-width: 30%;
    height: auto;
  }

  .options-list {
    display: none; /* Hide the desktop options list */
  }

  .related-coupon-cards {
    width: 100%;
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .related-coupons {
    text-align: center;
  }

  .related-slider {
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .customer-reviews {
    text-align: center;
  }

  .dashed-box {
    right: 5px;
  }

  .deals-grid-horizontal {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .deals-grid-horizontal :deep(.coupon-card) {
    flex: 0 0 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
}

@media (max-width: 750px) {
  .step-preview {
    padding: 0 10px;
    margin-top: 350px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    width: 90%;
  }

  .action-button {
    padding: 10px 15px;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .deals-grid-horizontal {
    flex-direction: column;
    align-items: center;
  }

  .deals-grid-horizontal :deep(.coupon-card) {
    flex: 0 0 calc(50% - 15px);
    max-width: calc(80% - 15px);
  }

  .deals-grid-horizontal :deep(.dashed-box){
    right: 80px;
  }

  .deals-grid-horizontal :deep(.coupon-card:hover) {
    transform: translateY(-3px);
  }

  .image-viewer {
    height: 300px;
  }

  .image-preview {
    width: 80px;
    height: 80px;
  }

  .ad-item img {
    max-width: 50%;
  }

  .mobile-options-list {
    display: block; /* Show the mobile options list */
    padding: 50px 0;
    text-align: center;
  }

  .option-label {
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .coupon-code-container, .coupon-code-flex {
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .modal {
    width: 90%;
    padding: 15px;
  }

  .switch {
    width: 50px;
    height: 28px;
  }

  .slider:before {
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
  }

  input:checked + .slider:before {
    transform: translateX(22px);
  }

  .options-list {
    order: -1; /* Move options list above the content on mobile */
  }

  .about-deal {
    margin-top: 20px;
  }

  .related-coupon-cards {
    grid-template-columns: 1fr; /* Ensure single column on smaller screens */
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .related-slider {
    justify-items: center;  /* Align center horizontally */
    align-items: center;
  }

  .separator {
    width: 100%;
  }

  .shopping-bag,
  .print,
  .e-wallet,
  .subscribe-button {
    width: 75%;
  }

  .business-info{
    font-size: 12px;
  }

  .modal {
    max-width: 300px;
  }

  .section-nav {
    justify-content: center; /* Centers the nav items on mobile */
    text-align: center;      /* Ensures text is centered */
    gap: 15px;               /* Reduce the gap for better mobile fit */
  }

  .section-nav a {
    font-size: 16px;         /* Adjust font size for mobile */
  }
}
</style>

